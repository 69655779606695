<template>
	<div class="recover-alert">
		<img
			src="~@/assets/img/svg/broker/mail-verif.svg"
			alt="email verification"
			decoding="async"
		/>
		<h1>
			{{ $t('web.recover_alert_text') }}
		</h1>
		<or-button @click="$emit('prev')" class="recover-alert__btn">
			{{ $t('web.back') }}
		</or-button>
	</div>
</template>

<script></script>

<style lang="scss">
.recover-alert {
	display: flex;
	flex-direction: column;
	max-width: 340px;
	gap: 40px;
	align-items: center;
	& > img {
		width: 100px;
		height: 100px;
	}
	& h1,
	p {
		margin: 0;
		padding: 0;
		color: var(--text-1, #000);
		text-align: center;
		font-size: 16px;
		font-weight: 700;
		line-height: normal;
	}
	&__btn.button.main {
		min-height: 48px;
		padding: 0 20px;
		width: 100%;
	}
}
</style>
