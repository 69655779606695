<template>
	<form @submit.prevent="submit" class="recover-password broker-form">
		<h1 class="broker-form__title">
			{{ $t('web.recover') }}
			<br />
			{{ $t('web.password') }}
		</h1>
		<div class="broker-form__input">
			<div class="broker-form__input-item">
				<label for="recovery-password" class="broker-form__input-label">
					{{ $t('web.email') }}
				</label>
				<or-input
					required
					v-model="form.email"
					id="recovery-password"
					class="main-type"
					:placeholder="$t('web.email')"
					@input="clearError('email')"
					:error="errors.email"
				/>
			</div>
		</div>
		<or-button type="submit" class="broker-form__btn">
			{{ $t('web.send') }}
		</or-button>
	</form>
</template>

<script>
import requestConfigs from '@/api/requestBrokerConfigs'
import { createRequestBroker } from '@/api/requestBuilder'

export default {
	data() {
		return {
			form: {
				email: ''
			},
			errors: {}
		}
	},
	beforeMount() {
		Object.keys(this.form).forEach((key) => {
			// Set field errors
			this.errors[key] = { text: '', status: false }
		})
	},
	methods: {
		clearError(type) {
			this.errors[type].status = false
			this.errors[type].text = ''
		},
		submit() {
			let isSubmit = true

			Object.entries(this.errors).forEach(([key, value]) => {
				if (key === 'status' && value) {
					isSubmit = false
				}
			})

			if (!isSubmit) {
				return
			}

			createRequestBroker(requestConfigs.POSTAccountRecovery, {
				jsonPayload: {
					...this.form
				}
			})
				.then((response) => {
					if (response.status) {
						this.$emit('success')
					}
				})
				.catch((err) => {
					if (err?.errors && Object.keys(err.errors).length) {
						Object.entries(err.errors).forEach(([key, value]) => {
							if (this.errors[key]) {
								this.errors[key].status = true
								this.errors[key].text = value.join(', ')
							}
						})
					}
				})
		}
	}
}
</script>
